.cfra-ag-grid {
    .ag-theme-alpine {
        --ag-header-background-color: #fff;
        --ag-header-foreground-color: #57626a;
        --ag-font-size: 15px;
        --ag-borders: solid 0px;
    }

    .ag-header-row {
        font-family: GraphikMedium;
        font-weight: 500;
    }

    .ag-header {
        border-top: 1px solid #74828d;
        border-bottom: 1px solid #74828d;
    }

    .ag-header-cell:first-child,
    .ag-cell:first-child {
        padding-left: 28px;
    }

    .ag-header-cell:last-child,
    .ag-cell:last-child {
        padding-right: 28px;
    }

    .ag-row {
        .company-details-url {
            color: #007bb8;
            text-decoration: none;
        }
        font-family: GraphikRegular;
        color: #002b5a;
        font-size: 15px;
    }

    .ag-root-wrapper {
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
    }

    .ag-header-cell-text {
        -webkit-line-clamp: 2;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        white-space: normal;
        word-wrap: break-word;
    }

    .ag-right-aligned-header .ag-header-cell-text {
        text-align: right;
    }
}
